.permissions-table {
  text-align: center;
}

.permissions-table-search {
  display: block;
  border: 1px solid #ebebeb;
  padding: 0.45rem;
  width: 100%;
}

.permissions-table-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}
