.user-banner__container {
  @apply py-3 flex flex-row pl-0 pr-3;
}

.user-banner__container .c-badge {
  @apply font-medium tracking-wider;
}

@screen sm {
  .user-banner__container {
    @apply pt-3 pb-3 pl-5;
  }

  .user-banner__container .c-badge {
    @apply font-semibold;
  }
}
