.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  min-height: 150px;
  box-shadow: 1px 3px 6px 6px rgba(0, 0, 0, 0.75);
}

.modal__inner {
  background-color: #fff;
  outline: 0;
}

.modal__header,
.modal__body,
.modal__footer {
  padding: 1.5rem;
}

.modal__footer {
  display: flex;
  justify-content: flex-end;
  background-color: #f5f6fa;
}

.modal__cancel-btn {
  border: 1px solid #b9bbbf;
  padding: 0.5rem;
  border-radius: 4px;
}

.modal__heading {
  font-size: 1.2rem;
  color: #1d2b4d;
}

.modal__label {
  display: block;
  color: #515869;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.modal__input {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  height: 40px;
  padding: 0rem 1rem 0rem 1rem;
  min-width: 250px;
}

.modal__input--error {
  border-color: #d62041;
}

.modal__input-error {
  display: flex;
  align-items: center;
  color: #d62041;
  font-size: 0.9rem;
  padding: 0.5rem 0;
}

.modal__input-error-icon {
  display: inline-block;
  width: 15px;
  margin-right: 0.3rem;
}

.modal--error {
  border-top: 5px solid #ff5271;
}

.modal--success {
  border-top: 5px solid #00c18b;
}

/*** Modal Stylying Amendments ***/

.modal__footer button {
  min-width: 90px;
  text-align: center;
}

.modal__cancel-btn {
  margin-right: 0.5rem;
}

.modal__header.amended-modal-header{
  padding: 0 0 20px 40px;
}

.modal__header.amended-modal-header h3{
  height: 25px;
  max-width: 550px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1D2B4D;
  margin: 38px 0 8px 0;
}

.modal__body.amended-modal-body{
  padding: 10px 40px;
}

.modal__body.amended-modal-body p{
  padding: 16px 0 0 0;
}

.footer-color {
  background-color: #f5f6fa !important;
}

.modal__footer.amended-modal-footer{
  height: auto;
  width: 100%;
  padding: 44px 34px 34px 34px;
  margin: 0;
}

.modal__footer button{
  margin: 0 0 0 26px;}

.modal__footer .c-button--danger{
  width: 155px;
}

.modal__footer.amended-modal-footer button{
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  text-decoration: none !important;
}