body{
  min-width: 1410px;
}
.c-sidenav {
  width: 22rem !important;
  @apply bg-freshfields-grey-300;
}

.civil-claims-container {
  display: flex;
  flex-direction: row;
}

.civil-claims-container__content {
  background-color: #fff;
  width: -webkit-fill-available;
}

.civil-claims-container__content-inner {
  padding: 0px 30px 30px 30px;
}

.c-button--secondary {
  border-color: transparent;
}

.civil-claims-container__header {
  display: flex;
  flex-direction: row;
  padding-right: 5%;
}

.c-search {
  width: 100%;
}

.civil-claims-contianer__data-table {
  padding-top: 1.2rem;
}

.inherit-permissions {
  display: flex;
  margin-bottom: 1rem;
}

.inherit-permissions label {
  margin: 0 0.5rem;
}

.permissions-table-buttons {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
}

.page-info-icon {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

/*********************************/
/********* Breadcrumbs ***********/
/*********************************/

.cc-breadcrumbs-container, .breadcrumbs-container h3 {
  margin: 0px 0;
  width: 100%;
}

.breadcrumbs-container h3 {
  padding-right: 300px;
  font-size: 14px !important;
}

.breadcrumbs-container h3 font{
  padding-right: 20px;
}

.cc-breadcrumbs{
  bottom: 8px;
  display: inline-flex;
  text-align: left;
}

.cc-breadcrumbs-container li > *{
  font-size: 14px !important;
}

/***end breadcrumbs styling***/

/*********************************/
/******* Search Container ********/
/*********************************/

.search-container{
  position: relative;
  display: inline;
  width: 320px;
  float: right;
  right: 0px;
  margin: 10px 0 0 0px !important;
}

.c-search__search-term{
  height: 40px !important;
}

.c-search__search-term:focus{
 outline-color: #C2F2FF !important;
}

.c-search__glass-icon{
  margin-top: -8px !important;
}

#search-check-options{
  position: relative !important;
  display: inline-block !important;
  justify-content: flex-start !important;
  max-width: 140px !important;
  width: 140px !important;
  margin: 5px 0px 5px 20px;
}
.search-checkbox{
  height: 12px;
  width: 12px;
}


/***end search container styling***/


/**************************************/
/***** Pagination Global Styling ******/
/*(placed here because of scope issues*/
/**************************************/

/***Pagination Component Material-ui Overrides ***/

.pagination-page-bottom{
  min-width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  margin: -20px auto 16px auto;
} 

.pagination-item{
  min-width: 110px;
  margin: 0 0px;
  padding: 30px 16px !important;
  position: relative;
  display: inline-flex;
}

/***Go To Page Styling***/
#go-to-page{
  position: absolute;
  height: 18px;
  top: 0;
  width: 90px;
  padding: 22px 0 0 0;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #292929;
}

#go-to-page-input{
  position:absolute;
  height: 24px;
  width: 90px;
  max-width: 90px;
  left: 0px;
  top: 16px;
  border-radius: 0px;
  border: 1px solid #EBEBEB !important;
  background-color: transparent;
  padding: 2px 10px;
  font-family: Montserrat;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

/***Result Count - Showing result x of y of total results***/

.rc-components{
  width: 140px;
}

#resultCount {
  width: 140px !important;
  height: 18px;
  position: relative;
  display: inline;
  margin: -20px 0 0 0;
  padding: 6px 20px;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #292929;
}

#resultCount p{
  height: 18px;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 18px;
  margin: 0px 0 10px 0;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #292929;
}

.pagination-bold{
  font-weight: 500;
  margin: 0 3px;
}

/***Number Of Results (component styling in NumberResultsSelect.css)***/
.number-result-select-container{
    margin-top: 24px;
    width: 100px;
    margin-left: 30px;
    position: relative;
}

/***Pagination Styling (page navigation at bottom of the page)***/

.pagination-next-page{
  margin: -45px 0 0 -20px;
}

#cc-pagination{
  position: relative;
  display: inline-flex;
  justify-content: flex-end !important;
  width: 100%;
  padding: 10px 0;
  margin: 0px 0 0 0;
}

#cc-pagination li, button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.MuiPaginationItem-textPrimary{
  background: transparent;
  color: #292929;
  border: none;
  border-radius: 0 !important;
  font-family: Montserrat;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 30px;
  width: 30px !important;
  margin: 16px 8px !important;
}

.pagination-page-bottom button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.MuiPaginationItem-textPrimary.Mui-selected{
  border-top: 2px solid #57C9E8;
}
