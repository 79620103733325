/*** Sort Component Styling ***/


.cc-sortBy{
    height: auto;
    display: inline;
    float: left;
    margin: -16px 10px 10px 0px !important;
  }  

.cc-sortBy .MuiInputBase-input.MuiOutlinedInput-input{
    font-size: 12px !important;
    min-width: 100px;
    left: 0 !important;
    padding: 16px 24px !important;
}

.cc-sort-dropdown .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    padding: 3px 13px;
    margin: 0 0 0 -16px;
    font-family: Montserrat;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    font-size-adjust: none;
    color: #292929 !important;
}

.cc-sort-dropdown:before, .cc-sort-dropdown:after{
    border: none !important;
    left: 0;
    top: 0;
}

.cc-sort-dropdown .MuiSelect-select:focus{
    background-color: #fff !important;
}

.cc-sortBy .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .cc-sortBy .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #57C9EB !important;
}

.cc-sortBy label{
    background-color: #fff !important;
}

.cc-sortBy .MuiFormLabel-root.Mui-focused{
    color: #1D2B4D !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded{
    border-radius: 0px;
    padding: 4px, 0px, 4px, 0px;
    box-shadow: 0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A, 0px 0px 0px 1px #0000000D;
    margin: 4px 0 0 0px !important;
}

.cc-sort-dropdown-option{
    color: #111827 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

.cc-sort-dropdown-option, .cc-sort-dropdon-option.cc-selected{
    background-color: #fff !important;
}

.cc-sort-dropdown-option.cc-selected:after{    
    content:'\2713';
    display:inline-block;
    vertical-align: top;
    line-height: 18px;
    height: 18px;
    width: 24px;
    border-radius: 0px;
    margin-right: 0.3em;
    text-align: center;
    color: #1D2B4D;
    position: absolute;
    top: 4px !important;
    right: 8px !important;
    font-weight: 600;
    font-stretch: extra-expanded !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected{
    background-color: #fff !important;
}