
/****New Folder Modal Styling****/

.modal__body.new-folder-body{
  padding: 0;
}

.modal__body.new-folder-body label{
  height: 21px;
  width: 449px;
  left: 40px;
  top: 0;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left; 
  margin: 8px 0 8px 40px;
}

#addNewFolderPart1.modal__input, #addNewFolderPart2.modal__input{
  height: 43px;
  width: 500px;
  border-radius: 0px;
  margin: 0 33px 8px 42px;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  padding: 12px 11px 12px 19px;
}

#addNewFolderPart1.modal__input:hover, #addNewFolderPart1.modal__input:active, #addNewFolderPart1.modal__input:focus,
#addNewFolderPart2.modal__input:hover, #addNewFolderPart2.modal__input:active, #addNewFolderPart2.modal__input:focus{
  outline-color: #C2F2FF;
}

