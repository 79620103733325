body > *{
  font-family: 'Montserrat' !important;
}

.MuiPopover-root{border-color: transparent !important;}

.data-table {
  width: 100%;
  max-width: inherit;
  text-align: left;
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 1;
}

.data-table tr {
  width: 100%;
}

.data-table thead {
  background-color: #f4f6fa;
  max-width: 100% !important;
}

.data-table,
.data-table td,
.data-table th {
  border: 1px solid #ebebeb;
  font-size: 14px !important;
}

.data-table td,
.data-table th {
  padding: 3px 0.25rem;
  max-width: 410px;
  min-width: 25px;
}

.col-name, .col-modified{
  min-width: 100px !important;
}

.data-table tbody tr:nth-child(even) {
  background-color: #f4f6fa;
}

.padding-top-0 {
  padding-top: 0 !important;
}


.u-heading-xs {
  font-size: 1.125rem;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.25s ease-in 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

*{
  outline-color: #C2F2FF !important;
}

/********************************************/
/****Material UI Overrides - Tag Styling ****/
/********************************************/

/*** Base Pill Styling for clickable chip ***/

.MuiChip-colorPrimary.MuiChip-clickableColorPrimary{
  background-color: #ebebeb !important;
  color: #292929 !important;
  margin: 3px 10px 6px 0;
  left: 0;
  top: 0;
  padding: 4px 8px;
  height: 28px;
  border-radius: 2rem;
  font-weight: 500;
  font-size: .75rem;
  display: inline-flex;
  flex-wrap: wrap;
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat';
}

.MuiChip-colorPrimary.MuiChip-clickableColorPrimary svg{
  margin-top: -3px !important;
}

/***  Pill boxes should have a drop shadow on hover: ***/
/* Material design elevation styles/mdc-elevation--z4 */

.MuiChip-colorPrimary.MuiChip-clickableColorPrimary:hover, 
.MuiChip-colorPrimary.MuiChip-clickableColorPrimary:active,
.MuiChip-colorPrimary.MuiChip-clickableColorPrimary:focus{
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  background-color: #ebebeb !important;
  color: #292929 !important;
} 

.MuiChip-colorPrimary.MuiChip-clickableColorPrimary:after, 
.MuiChip-colorPrimary.MuiChip-clickableColorPrimary:before {
  box-sizing: border-box;
  border: 0 solid #ebebeb;
}

/*** Information Pill Styling for deletable chip ***/

.MuiChip-colorPrimary.MuiChip-deletable{
  background-color: #e4bcec !important;
  color: #491454 !important;
  border: 1px solid #e4bcec !important;
  margin: 3px 10px 6px 0;
  left: 0;
  top: 0;
  padding: 4px 8px;
  height: 28px;
  border-radius: 2rem;
  font-weight: 500;
  font-size: .75rem;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat';
}

 .MuiChip-colorPrimary.MuiChip-deletable svg{
  color: #491454 !important;
  background-color: transparent !important;
  margin-left: 4px;
  height: 18px;
  width: 18px
}

.MuiChip-colorPrimary.MuiChip-deletable:hover, 
.MuiChip-colorPrimary.MuiChip-deletable:focus, 
.MuiChip-colorPrimary.MuiChip-deletable:active {
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  background-color: #e4bcec !important;
  color: #491454 !important;
}  


/********************************************/
/**Amended Styling of the Action Bar Toasts**/
/********************************************/

.c-feedback-block-body{
  min-width: 514px !important;
  padding: 25px 64px 26px 34px !important; 
}

.u-heading-xs.heavy-font{
  font-weight: 700 !important;
}

.u-heading-xs{
  font-family: Montserrat !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 23px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  margin-bottom: 10px !important;
}

.react-toast-notifications__container button{
   color: transparent !important;
}

.react-toast-notifications__container:hover button{
  color: #292929 !important;
}


