/* styles @material-ui/core/Drawer */

.MuiPaper-root.MuiDrawer-paperAnchorRight {
  padding: 0.5rem 2rem 0.5rem 2rem;
  width: fit-content;
}

.MuiPaper-root.MuiDrawer-paperAnchorRight .default-drawer, .MuiPaper-root.MuiDrawer-paperAnchorRight .restore-drawer{
 max-width: 520px !important;
 min-width: 520px !important;
}

.MuiPaper-root.MuiDrawer-paperAnchorRight .jobs-drawer{
  max-width: 1040px !important;
  min-width: 1040px !important;
 }

.MuiPaper-root.MuiDrawer-paperAnchorRight .drawer-close {
  width: 30px;
  height: 30px;
  padding: 1.5rem 2rem;
  font-size: 3rem;
  line-height: 0;
  position: absolute;
  display: block;
  top: 0px;
  right: 8px;
}

.MuiPaper-root.MuiDrawer-paperAnchorRight .mb-2{
  font-family: Montserrat;
  font-style: medium;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  vertical-align: top;
  letter-spacing: 0.2px;
  color: #1D2B4D;
  margin: 0;
  padding: 0px 0;
}


.MuiPaper-root.MuiDrawer-paperAnchorRight .panel-search-card{
  width: 100%;
  margin: 8px 0;
  background:#F8F9FA;
  box-sizing: border-box;
  padding: 16px 24px 40px 24px;
  min-height: auto;
  height: auto;
  position: relative;
}

p.panel-card-header{ margin: 0 8px; padding: 0; line-height: 1rem; font-size: .875;}

.MuiPaper-root.MuiDrawer-paperAnchorRight #copyToFolder1, .MuiPaper-root.MuiDrawer-paperAnchorRight #copyToFolder2, 
.MuiPaper-root.MuiDrawer-paperAnchorRight #moveDocument1, .MuiPaper-root.MuiDrawer-paperAnchorRight #moveDocument2{
  height: 40px !important;
  padding: 8px 24px !important;
  margin: 10px 8px 16px 8px;
  position: absolute;
  right: 16px;
  bottom: 0px;
}

.MuiPaper-root.MuiDrawer-paperAnchorRight .restore-drawer .mr-2{
  padding-bottom: 30px;
}

.c-button.c-button--secondary{
  border: 1px solid #b9bbbf;
}

.search-autocomplete-container{
  min-height: 280px;
  box-sizing: border-box;
  padding-bottom: 25px;
  margin: 0;
}

.search-results-container{
  margin: 0;
  padding: 8px 0;
  max-width: 468px;
  height: 120px;
  min-height: 120px;
  position: relative;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
}

.dropdown-container{
  height: 110px !important;
  max-height: 110px;
  min-height: 110px;
  margin: auto 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

.search-results-dropdownList{
  margin: 0 0 0px 0;
  top: 0px;
  padding: 0rem 1.25rem 1rem 1rem;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  display: block;
  pointer-events: all;
}

.search-results-dropdownList li{
  position: relative;
  display: inline-block;
  top: 0px;
  left: 0px;
  height: auto;
  padding: 0px 2px;
  font-size: .775rem;
  font-weight: 400;
  line-height: 1.2;
  color: #6D7383;
  max-width: 100%;
  width: 100%;
  margin: 6px 0;
  cursor: pointer;
}

.sr-foldername, .selection-foldername{
  font-size: .875rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: block;
  min-width: 100%;
  padding-bottom: 4px;
}

.sr-location, .selection-location{
    position: relative;
    display: inline-block;
    top: 0px;
    left: 0px;
    height: auto;
    padding: 0;
    font-size: .725rem ;
    font-weight: 400 ;
    line-height: 1.2;
    color: #6D7383;
    max-width: 100%;
    width: 100%;
    margin: 0;
}

.mui-style-location span{
  color: #292929;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1.1rem;
}

.mui-style-location{
  display: block;
  top: 0;
  left: 0px;
  height: auto;
  min-height: 90px;
  background: transparent;
  margin: 0;
  padding: 8px 0 0 0;
  width: 100%;
  position: relative;
  margin-top: 0px;
  box-sizing: border-box;
}

.mui-style-label{
  position: relative;
  display: inline-block;
  top: 0px;
  left: 0px;
  height: auto;
  padding: 4px 0px;
  font-size: .775rem;
  font-weight: 600;
  line-height: 1.25;
  color: #1D2B4D;
  max-width: 100%;
  width: 100%;
  background: transparent;
}

.mui-style-selection{
  position: relative;
  display: inline-block;
  top: 0px;
  left: 0px;
  height: auto;
  padding: 8px 8px;
  max-width: 100%;
  width: 100%;
  background: #fff;
  height: auto;
  min-height: 60px;
  height: auto;
}

.MuiPaper-root.MuiDrawer-paperAnchorRight .ml-6.my-6{
  margin: 0;
  padding: 0 0px 16px 8px;
  max-width: 468px;
  max-height: 190px;
  height: 190px;
}

.MuiPaper-root.MuiDrawer-paperAnchorRight .ml-6.my-6 .folder-tree__independent{
  box-sizing: border-box;
  min-height: 100% !important;
  max-height: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}


.MuiPaper-root.MuiDrawer-paperAnchorRight .ml-6.my-6 .folder-tree__independent .rst__tree{
  box-sizing: border-box;
  min-height: 100% !important;
  max-height: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 10px;
  padding: 0px 0;
}

.MuiPaper-root.MuiDrawer-paperAnchorRight .text-sm.uppercase.mb-3{
  margin: 0;
  padding: 0px 0;
  color: #1D2B4D;
  font-weight: 700;  
}

.folder-tree-header {
  padding: 0px 15px 20px 16px;
}

/*** dropdown styling on folderRetreiver ***/
/** possible IE11 fix for closing dropdown**/

.search-results-dropdownList:not(.sticky) > *:not(:last-child):focus, 
.search-results-dropdownList:focus {
  pointer-events: none; /* Causes second click to close */
}