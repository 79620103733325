.action-bar-button {
  padding: 0.1rem;
  margin: 0.4rem 0.8rem;
}

.action-bar-button button {
  padding: 0.2rem;
  border: none !important;
}

.action-bar-button button {
  height: 100%;
}

.action-bar-button__content {
  display: flex;
}

.action-bar-button__icon {
  margin: auto 4px auto auto;
  flex-grow: 0 !important;
  max-width: 100%;
  flex-shrink: 0;
  height: 14px !important;
  width: 14px !important;
  vertical-align: middle !important;
}

.action-bar {
  display: flex;
  align-items: left;
  @apply bg-freshfields-grey-300;
}

.action-bar__info-button {
  padding: 0.1rem;
  margin-left: auto;
  min-height: 100%;
}

.c-button--secondary:disabled:not(.c-button--loading) {
   background-color: #f4f6fa;
   opacity: 0.5;
}



