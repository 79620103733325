.c-action-line {
    @apply flex;
  }
  
  .c-action-line__line-col {
    @apply bg-freshfields-grey-500 w-1 flex flex-col justify-between items-center mx-3 mt-1;
  }
  
  .c-action-line__contents-col {
    @apply flex flex-grow flex-col w-full pl-2;
  }
  
  .c-action-line__line-col-circle {
    @apply bg-freshfields-grey-500 rounded-full w-3 h-3;
  }