.c-time {
    @apply text-freshfields-grey-600 flex items-center;
  }
  
  .c-time-text {
    @apply text-freshfields-grey-800 text-xs ml-1 lowercase;
  }
  
  @screen sm {
    .c-time-text {
      @apply text-sm;
    }
  }
  