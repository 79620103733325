.jobs-chip .MuiChip-clickableColorPrimary.MuiChip-sizeSmall.MuiChip-clickable {
    width: 70px;
    padding: 2px;
    margin: 3px;
}

.jobs-chip .MuiChip-clickableColorPrimary.MuiChip-sizeSmall.MuiChip-clickable >* {
    display: inline-block;
    font-size: 0.775rem;
    margin:0px;
    padding: 0;
}

.jobs-chip .MuiChip-clickableColorPrimary.MuiChip-sizeSmall.MuiChip-clickable svg {
    margin: 0 3px 0 -5px;
    padding: 1px;
}