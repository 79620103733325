.portal-app-name {
  font-weight: 500;
  font-size: 1.5rem;
  display: 'flex';
  text-transform: 'uppercase';
}

.portalHeader__fullLogo {
  background-size: contain;
  width: 188px;
  height: 33px;
  text-indent: -5000px;
}

.portal-ui-layout-header {
  margin-bottom: 1.45rem;
}

.portal-header-bound {
  margin: 0 auto;
  max-width: 960;
  padding: 1.45rem, 1.0875rem;
}

.c-ds-top-nav-wrapper {
  min-width: 100%;
  @apply inline-flex items-center uppercase text-3xl h-full w-full;
}

.c-ds-top-nav-wrapper__small-logo {
  height: 32px;
  @apply mr-4 w-auto;
}

.c-ds-top-nav-wrapper_large-logo {
  height: 32px;
  @apply mx-8 hidden w-auto;
}

.c-ds-top-nav-wrapper_product-name {
  @apply text-sm font-medium;
  display: inline-block !important;
  position: absolute;
  top: 15px;
  left: 220px;
  width: 200px !important;
}

@screen md {
  .c-ds-top-nav-wrapper__small-logo {
    @apply hidden;
  }

  .c-ds-top-nav-wrapper_large-logo {
    @apply inline-block;
  }

  .c-ds-top-nav-wrapper_product-name {
    padding-top: 0.15rem;
    @apply text-3xl;
  }
}

.header-nav {
  height: 4rem;
}

.header-nav .user-profile {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.header-nav .c-badge {
  margin: 0 18px;
}
