
#cc-folder-tree {
  padding-bottom: 20px;
  height: 99%;
  min-height: 600px;
  min-width: 348px;
  max-width: 100%;
  box-sizing: border-box;
}

.rst__tree {
  outline: none;
  overflow-x: hidden;
}

.ReactVirtualized__Grid, .ReactVirtualized__List {
  outline: none;
}

.rstcustom__row .rstcustom__rowLabel {
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 264px;
  padding-left: 3px;
}

.folder-tree__independent .rstcustom__row .rstcustom__rowLabel a:focus {
  outline: 2px #1d2b4d solid;
  border-radius: 2px;
}

/*Bug fix to ensure that side navigation buttons are aligned in IE 11 as well as other browsers*/
.rstcustom__node{left: 27px !important; display: block !important;}
.rstcustom__expandButton{margin-top: 15px !important;}
.rstcustom__expandButton::after, .rstcustom__expandButton:focus::after{top: 0 !important; }

.rstcustom__collapseButton{margin-top: 15px !important; display: block !important;}

.rstcustom__collapseButton::after{right: 15px !important; top: 0px !important; display: block !important;}
.rstcustom__collapseButton::before{right: 15px !important; top: 0px !important; display: block !important;}


font{
  padding: 1px;
  outline-offset: 0px;
}


