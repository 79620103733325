.cc-dropdown{
    width: 90px;
    border: 1px solid #EBEBEB !important;
    height: 30px;
    width: 90px;
    border-radius: 0px;
    padding: 9px 13px;
    position: relative !important;
    display: inline-block;
    margin: 8px 0 0 -16px;
    background-color: #fff !important;
}

.cc-dropdown .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    padding: 3px 13px;
    margin: 0 0 0 -16px;
    width: 110px;
    font-family: Montserrat;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    font-size-adjust: none;
    color: #292929 !important;
}

.cc-dropdown:before, .cc-dropdown:after{
    border: none !important;
    left: 0;
    top: 0;
}

.cc-dropdown.Mui-focused.Mui-focused {
    background-color: #fff !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded{
    width: 86px;
    border-radius: 0px;
    padding: 4px, 0px, 4px, 0px;
    box-shadow: 0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A, 0px 0px 0px 1px #0000000D;
    margin: 4px 0 0 4px !important;
}

.number-result-select-container label{
    margin: 0px 0px 0px -16px !important;
    font-size: 12px !important;
}

.cc-dropdown-option{
    color: #111827 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    margin: 8px 0 !important;
    min-width: 90px;
}

.cc-dropdown-option, .cc-dropdon-option.cc-selected{
    background-color: #fff !important;
    padding: 6px 8px;
    margin: 0;
}

.cc-dropdown-option.cc-selected:after{    
    content:'\2713';
    display:inline-block;
    vertical-align: top;
    line-height: 18px;
    height: 18px;
    width: 24px;
    border-radius: 0px;
    margin-right: 0.3em;
    text-align: center;
    color: #1D2B4D;
    position: absolute;
    top: 4px !important;
    right: 8px !important;
    font-weight: 600;
    font-stretch: extra-expanded !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.cc-dropdown.Mui-focused.Mui-focused .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input{
    background-color: #fff !important;
}
