.action-report {
  margin-bottom:1.25em
  }
  
  .action-report:last-of-type {
    @apply mb-6;
  }
  
  .action-report__badge {
    margin-left: -2.1rem;
    @apply absolute;
  }
  
  .action-report__line {
    @apply flex-col;
  }
  
  .action-report__line span {
    @apply pr-1;
  }
  
  .action-report__text {
    @apply text-sm text-freshfields-grey-700;
    margin-top: 0.15rem;
  }
  
  .action-report__text--action {
    @apply italic text-freshfields-grey-700 pr-1;
  }
  
  .action-report__text--blue {
    @apply text-freshfields-brand-blue pr-1;
  }
  
  .action-report__text--link {
    @apply cursor-pointer underline;
  }
  
  .action-report__time {
    margin-top: 0.15rem;
    float:right;
  }
  
  .action-report__note {
    @apply text-freshfields-grey-900 p-2 bg-freshfields-grey-200 whitespace-pre-line border-l-2 border-freshfields-grey-500 my-3 text-sm;
  }
  
  .c-badge--small {
    font-size: 0.7em !important;
    font-weight: 600;
  }
  .group-by-day__title {
    padding-top:5px;
    margin-bottom: 2em;
    background: white;
    font-size: 1em;
    text-align: center;
    @apply text-freshfields-grey-700
  }
  
  .group-by-day__divider {
    margin-top: -0.8em;
    margin-bottom: 2em;
  }
  
  .group-by-day__title-container {
    text-align: center;
    background: white;
    @apply text-freshfields-grey-900 mt-6;
  }

  .group-by-day__line {
    width:80%;
    margin: auto;
  }
  
