@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

/* @import "../node_modules/@freshfields/react-component-library/dist/component-library/fonts/settings.css"; */
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/elements.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/objects.css";

@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/Badge.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/Link.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/forms.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/Model.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/Table.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/FeedbackBlock.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/ContextMenu.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/Button.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/Tabs.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/Pill.css";
@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/Search.css";

@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/components/UserDetails.css";

@import "../node_modules/@freshfields/react-component-library/dist/component-library/css/utilities/accessibility.css";

#rootError {
    text-align: center;
    padding-top: 2em;
}

@font-face {
    font-family: "Montserrat";
    src: url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Light.woff2") format("woff2"), url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Light.woff") format("woff"),
      url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Regular.woff2") format("woff2"), url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Regular.woff") format("woff"),
      url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Medium.woff2") format("woff2"), url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Medium.woff") format("woff"),
      url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-SemiBold.woff") format("woff"),
      url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Bold.woff2") format("woff2"), url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Bold.woff") format("woff"),
      url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-ExtraBold.woff") format("woff"),
      url("../node_modules/@freshfields/react-component-library/dist/component-library/fonts/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
  }
  