/**Material UI Styling override ***/
/**Causes issue in tabs in IE11***/
@media (min-width: 600px){
  .MuiTab-root {
      min-width: 150px !important;
  }}
  

.tabs-panel{
  padding-top: 2rem;
}

.tabs-panel .MuiAppBar-colorPrimary {
  background-color: white;
  margin: 1.5rem 0 2rem 0;
}

.tabs-panel .MuiTab-textColorInherit {
  color: #6c758b;
}

.tabs-panel .MuiTab-textColorInherit.Mui-selected {
  outline: none;
  color: #57c9e8;
}

.tabs-panel .MuiTabs-indicator {
  background-color: #57c9e8;
}

.tabs-panel .MuiPaper-elevation4 {
  box-shadow: none;
}

.tabs-panel .MuiTabs-flexContainer {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #ebebeb;
}

.tabs-panel__heading {
  display: flex;
  align-items: center;
}

.tabs-panel__heading h3{
  font-size: 0.875rem;
  font-weight: 600;
}

.tabs-panel__heading .panel-header-icon{
  float: right;
  margin-left: 16px;
}
