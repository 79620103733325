.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 1px dashed #666666;
}

.inline-text_copy--active {
  cursor: pointer;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}
.inline-text_input {
  margin-top: 10px;
  outline: 2px white solid;
  border-bottom: 1px solid white;
  min-width: 200px;
  box-sizing: border-box;
  max-width: 420px;
  padding: 16px;
  text-align: left !important;
  flex-direction: row;
}

.inline-text_input--active {
  display: inline-block;
  border-bottom: 1px white #666666;
  min-width: 100%;
  margin: 0 8px 8px 0;
  font-size: 16px;
  padding: 16px 8px;
}

